<template>
    <!-- 店铺续费 -->
    <div class="shop-renew">
       <div class="sr-con">
            <com-program-renew :program-id="program_id" :trade-no="order_number"></com-program-renew>
        </div>  
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import comProgramRenew from '@/components/pay/com-program-renew.vue'

export default {
    components:{
        comProgramRenew
    },
    setup() {
        const _d = reactive({
            program_id:0,
            order_number:"",
        })
        
        const options = useRoute().query
        if( options.id ){
            _d.program_id = options.id
        }
        if( options.out_trade_no ){
            _d.order_number = options.out_trade_no
        }

        return{
            ...toRefs(_d),
        }
    },
}
</script>
<style lang="scss" scoped>
.sr-con{
    background: #fff;
    margin-top: 12px;
    padding: 24px;
}

</style>